<template>
  <div class="manifest_table_out_div">
<!--    <ul class="the_nav">-->
<!--      <li v-for="item in navList" :key="item.id" :class="{active: !(item.id !== menuIndex)}" class="nav_item">-->
<!--        <a>{{ item.title }} </a>-->
<!--        <span v-if="item.id !== '0'" class="close_span" @click.stop="closeSpan(item.id, item)"> ×</span>-->
<!--      </li>-->
<!--    </ul>-->
    <ManifestSearchBar @edit="edit" @search="searchList" :multiple-selection="multipleSelection"></ManifestSearchBar>
    <ShManifestTable
        :curr-page="currPage"
        :page-size="pageSize"
        :total-count="totalCount"
        :total-page="totalPage"
        :table-header="tableHeaders"
        :table-data="tableData"
        :type="search_completionstatus"
        :data-loading="dataLoading"
        @fromChild="getSelection"
        @refresh="getList"
        @edit="edit"
        @nextPage="nextPage"
        @pageSizeChange="pageSizeChange"
        ref="ShManifestTable">
    </ShManifestTable>
  </div>
</template>

<script>
import mixin from '@/mixin/manifest/sh/manifestTable'
import {mapMutations} from "vuex";

export default {
  mixins: [mixin],
  name: "DraftTable",
  data() {
    return {
      menuIndex: 0,
      search_completionstatus: 0, //搜索的舱单状态
      navList: [{
        title: "草稿箱",
        id: '0'
      }],
    }
  },
  methods: {
    ...mapMutations('ship', ['editGoodsuuid']),

    closeSpan(index, item) {
      this.navList = this.navList.filter((item) => {
        return item.id !== index;
      });
      if (this.menuIndex === index) {
        this.menuShow(0)
      }
      this.editGoodsuuid("");
    },
  }
}
</script>

<style lang="stylus" scoped>
.the_nav {
  width: 100%;
  margin: 1rem 0 0 0;
  height: 30px;
  background-color: white;

  .nav_item {
    background: var(--GRAY-dcdcdc);
    width: fit-content;
    min-width: 5rem;
    text-align: center;
  }
}

.active {
  background-color: rgba(13, 175, 255, 0.33);
}

ul {
  margin-bottom: 1rem;
  list-style: none;
  line-height: 30px;
}

ul a {
  text-decoration: none;
}

.close_span {
  display: inline-block;
  margin: 0 -1rem 0 1.5rem;
  cursor: pointer;
  padding: 0 1rem;
}

.close_span:hover {
  background-color: lightgrey;
}
</style>
